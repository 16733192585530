.header-default{
    .logo-header{
        width: 150px;
        margin: 10px;
    }

    margin-bottom: 30px;
}

.content-default{
}

.footer-default{
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;

    .copy{
        text-align: center;
        color: #8e8c8c;
        font-size: 13px;
        font-family: 'MyriadProCond', sans-serif;
        letter-spacing: 2px;
    }

    .logosp-footer{
        width: 100px;
        position: absolute;
        bottom: 2px;
        left: 10px;
    }
}
