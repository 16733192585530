.about{
    .logo{
		width: 300px;
        margin: 0 auto 20px auto;
        display: block;
    }

    p{
        text-align: center;
    }

	.logos-container{
        list-style: none;
        text-align: center;
        margin: auto;
        position: relative;
        display: block;

		li{
            display: inline;
            margin-right: 6%;

			img{
				width: 35%;
				min-width: 100px;
                max-width: 300px;
                margin: 10px 0;
			}
		}
	}

	.title{
		text-align: center;
	}

	.icono{
		width: 20%;
		max-width: 300px;
		margin: auto;
		display: block;
		margin-bottom: 15px;
	}

	p{
		font-size: 13px;
	    text-align: center;
	    margin-bottom: 0px;
	    color: #542763;
	}
}
