@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
       -moz-box-shadow: $shadow;
        -ms-box-shadow: $shadow;
            box-shadow: $shadow;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

@mixin animate($animation) {
    -webkit-animation: $animation;
       -moz-animation: $animation;
        -ms-animation: $animation;
            animation: $animation;
}

@mixin tranformer($animation) {
    -webkit-transform: $animation;
       -moz-transform: $animation;
        -ms-transform: $animation;
         -o-transform: $animation;
            transform: $animation;
}

@mixin transitioner($animation) {
    -webkit-transition: $animation;
       -moz-transition: $animation;
        -ms-transition: $animation;
         -o-transition: $animation;
            transition: $animation;
}

@mixin no-select() {
    -webkit-touch-callout: none; /* iOS Safari */

    -webkit-user-select: none;   /* Chrome/Safari/Opera */
     -khtml-user-select: none;   /* Konqueror */
       -moz-user-select: none;   /* Firefox */
        -ms-user-select: none;   /* IE/Edge */
            user-select: none;   /* non-prefixed version, currently not supported by any browser */
}

@mixin click() {
	cursor: pointer;
    @include no-select();
}
