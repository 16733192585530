.menu{
	position: absolute;
	z-index: 5;
	width: 100%;
	top: 0;
	left: 0;

    .logosp{
        position: absolute;
        top: 15px;
        left: 15px;
        width: 120px;

		@media screen and (max-width: $screen-xs) {
			width: 80px;
		}
    }

    .nav-mn{
        margin: 30px auto 0 auto;
        display: table;

        @include border-radius(10px);
        @include box-shadow(1px 1px 10px -1px #8c8b8b);

		@media screen and (max-width: $screen-sm) {
			float: right;
			margin-right: 20px;
		}

        .btn{
            background-color: #fff56f;
            color: #ff911c;
            border: 1px solid #FFF;
            margin-left: 0px;
            font-size: 18px;
            letter-spacing: 1px;
            font-family: "MMText", sans-serif;
            border-radius: 10px;
            padding: 7px 20px;

            @include border-radius(10px);

            &.active, &:hover{
                background-color: #00b6dd;
                color: #FFF;
                border: .5px solid #FFF;

                @include box-shadow(none);
            }

			@media screen and (max-width: $screen-sm) {
				float: none !important;
				display: block;

				&:first-child:not(:last-child):not(.dropdown-toggle){
					border-bottom-right-radius: 0;
					border-top-right-radius: 10px;
    				border-bottom-left-radius: 0;
				}

				&:last-child:not(:first-child), &.dropdown-toggle:not(:first-child){
					border-bottom-left-radius: 10px;
					border-top-right-radius: 0;
    				border-top-left-radius: 0;
				}
			}

        }
    }
}
