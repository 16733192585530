
/* form starting stylings ------------------------------- */
.groupmd			  {
  position:relative;
  // margin-bottom:45px;

  input 				{
      font-size:18px;
      padding:10px 10px 10px 5px;
      display:block;
      width:100%;
      border:none;
    //   border-bottom:1px solid #757575;

      &:focus 		{ outline:none; }

      /* active state */
      &:focus ~ label, &:valid ~ label 		{
          top:-20px;
          font-size:14px;
          color:#5264AE;
          padding-left: 34px;
      }

      /* active state */
      &:focus ~ .bar:before, &:focus ~ .bar:after {
          width:50%;
      }

      /* active state */
    //   &:focus ~ .highlight {
    //       -webkit-animation:inputHighlighter 0.3s ease;
    //       -moz-animation:inputHighlighter 0.3s ease;
    //       animation:inputHighlighter 0.3s ease;
    //   }
  }


}

/* LABEL ======================================= */
label 				 {
    color:#999;
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
}


/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:0%; }
.bar:before, .bar:after 	{
    content:'';
    height:2px;
    width:0;
    bottom:1px;
    position:absolute;
    background:#5264AE;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
}
.bar:before {
    left:50%;
}
.bar:after {
    right:50%;
}



/* HIGHLIGHTER ================================== */
.highlight {
    position:absolute;
    height:60%;
    width:100px;
    top:25%;
    left:0;
    pointer-events:none;
    opacity:0.5;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
