/**
 * Login Styles
 */

.login-container{
    width: 100%;
    height: auto;
    padding-bottom: 50px;

    .nav-mn-container{
        position: relative
    }

    .logosp{
        position: absolute;
        top: 15px;
        left: 15px;
        width: 150px;
    }

    .logo2{
        width: 65%;
        max-width: 582px;
        margin: 19% auto 0 auto;
        display: block;
    }

    .nav-mn{
        margin: 50px auto 0 auto;
        display: table;

        @include border-radius(10px);
        @include box-shadow(1px 1px 10px -1px #8c8b8b);

        .btn{
            background-color: #fff56f;
            color: #ff911c;
            border: 1px solid #FFF;
            margin-left: 0px;
            font-size: 18px;
            letter-spacing: 1px;
            font-family: "MMText", sans-serif;
            border-radius: 10px;
            padding: 7px 20px;

            @include border-radius(10px);

            &.active, &:hover{
                background-color: #00b6dd;
                color: #FFF;
                border: .5px solid #FFF;

                @include box-shadow(none);
            }
        }
    }

    .form-login{
        padding: 0;
        border: 0;

        form{
            margin-top: 40%;
            max-width: 560px;
            width: 100%;
            float: right;

            @include border-radius(10px);
            @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23));

            @media (max-width: 991px) {
                float: none;
                margin-right: auto;
                margin-left: auto;
                display: block;
            }
        }

        .help-block{
            display: block;
            margin: 0;
            margin-top: -1px;
            background-color: #fff468;
            position: relative;
            border-bottom: 1px solid #e5e5e5;
            font-family: "MyriadPro", sans-serif;
            padding-left: 40px;
            font-size: 17px;
            color: #c70a0a;
            padding: 10px 40px;
        }

        .form-group{
            margin-bottom: 0;
        }

        .groupmd{
            border: 1px solid #dadada;
            margin-bottom: -1px;

            input{
                height: 95px;
                font-size: 34px;
                color: #fed759;
                padding-left: 40px;
                font-family: "MyriadProCond", sans-serif;
                letter-spacing: 2px;

                &:focus ~ label, &:valid ~ label {
                    top:7px;
                    color: #fed759;
                    font-size: 17px;
                }
            }

            label{
                padding-left: 35px;
                font-size: 36px;
                top: 22px;
                color: #c7c7c7;
                font-family: "MyriadProCond", sans-serif;
                letter-spacing: 2px;
            }
        }

        .logo{
            position: absolute;
            bottom: 15px;
            margin: 0 auto;
            right: 0;
            left: 0;
            max-width: 366px;
            min-width: 0px;
            width: 80%;
        }

        .header-login{
            display: block;
            width: 100%;
            height: 85px;
            font-size: 21px;
            color: #333333;
            background-color: #fff468;
            border: 0;
            border-bottom: 1px solid #e5e5e5;
            margin: 0;
            position: relative;
            border-radius: 10px 10px 0px 0px;

        }

        .footer-login{
            margin: 0;
            border-top: 1px solid #c7d5ce;
            background-color: #ececec;
            margin-bottom: 0;
            padding: 19px 0 30px 0;
            border-radius: 0px 0px 10px 10px;

            .btn:last-child{
                margin-left: 40px;

                @media (max-width: 767px) {
                    margin-left: 15px;
                }
            }

            .center-block{
                display:table;
            }
        }

        .btn:hover, .btn:active{
            outline: none;
        }

        .btn.btn-link{
            padding-left: 0;
            font-family: "MyriadProCond", sans-serif;
            font-size: 18px;
        }

        .btn:not(.btn-link){
            padding: 7px 21px;
            border-radius: 10px;
            font-size: 19px;
            letter-spacing: 1px;

            &.btn-success{
                background-color: #00b64c;
                border-color: #00b46f;
                font-family: "MyriadProCond", sans-serif;

                &:hover{
                    background-color: #04a949;
                    box-shadow: inset 1px 1px 2px #757575;
                }
            }

            &.btn-primary{
                background-color: #00b0ee;
                border-color: #00addc;
                font-family: "MyriadProCond", sans-serif;
                
                &:hover{
                    background-color: #0399ce;
                    box-shadow: inset 1px 1px 2px #757575;
                }
            }
        }

    }

}
