.loading{
	position: absolute;
	display: table;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #FFF;
	z-index: 9999;

	&.out{
		opacity: 0;
		@include transitioner(opacity .5s ease-in-out);
	}

	> div{
		display: table-cell;
		vertical-align: middle;
		text-align: center;

		p{
			font-family: "MMText", sans-serif;
			color: #ff911c;
			font-size: 1.5em;
			margin-top: 10px;
			opacity: 0;

			&.in{
				-webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
			    -webkit-transition: all .5s ease-in-out;
			    -moz-transition: all .5s ease-in-out;
			    -o-transition: all .5s ease-in-out;
			    transition: all .5s ease-in-out;

				animation:fade 3000ms infinite;
	    		-webkit-animation:fade 3000ms infinite;
			}
		}
	}

}


@keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.4; }
    to { opacity: 1.0; }
}

@-webkit-keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.4; }
    to { opacity: 1.0; }
}
