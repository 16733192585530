/* Sidebar background color */
 $sidebarBaseColor: #30426a;
 /* Sidebar header and footer color */
 $sidebarHeaderColor: darken($sidebarBaseColor, 2%);
 /* Sidebar title text colour */
 $sidebarTitleColor:  lighten($sidebarBaseColor, 30%);


$colorGreen: #23ae89;
$colorBlue: #2361ae;
$colorOrange: #d3a938;
$colorRed: #ae2323;

$contentMnLeftDefault: 60%;
