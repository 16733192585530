/**
 * overhang.css
 * Paul Krishnamurthy 2016
 */

@import url(https://fonts.googleapis.com/css?family=Roboto);

.overhang {
  position    : fixed;
  display     : none;
  width       : 100%;
  z-index     : 9999;
  padding     : 15px 2px;
  top         : 0;
  left        : 0;
  right       : 0;
  font-family : "Roboto", sans-serif;
  text-align  : center;

  &.down{
      display: block;
      @include slideInDown(
          $duration: 1.5s,
          $delay: 0.5s,
          $function: ease
      )
  }

  &.up{
      @include slideOutUp(
          $duration: 1.5s,
          $delay: 0.2s,
          $function: ease
      )
  }

  &.success{
    background-color: #2ECC71;
    border-bottom: 6px solid #27AE60;
  }

  &.info{
    background-color: #3498DB;
    border-bottom: 6px solid #2980B9;
  }

  .overhang-message {
      color     : #FFF;
      font-size : 20px;
  }

  .overhang-prompt-field {
      height        : 30px;
      width         : 250px;
      border-radius : 5px;
      border        : none;
      display       : block;
      margin-left   : auto;
      margin-right  : auto;
      margin-top    : 15px;
      bottom        : 7px;
      position      : relative;
      padding-left  : 3px;
      padding-right : 3px;
      outline       : none;
      font-family   : "Roboto", sans-serif;
      font-size     : 15px;
  }

  .overhang-yes-option, .overhang-no-option {
      min-width     : 50px;
      height        : 30px;
      border        : none;
      outline       : none;
      border-radius : 4px;
      font-size     : 20px;
      color         : #FFF;
      cursor        : pointer;
      margin        : 0 6px;
  }

  .overhang-yes-option {
      margin-left : 15px;
  }

  .overhang-close {
      vertical-align : middle;
      cursor         : pointer;
      margin-top     : -4px;
      padding-left   : 13px;
      font-size      : 2.5em;
      font-weight    : bold;
      display        : inline-block;
      line-height    : 30px;
  }

  .overhang-close:after {
      content : "\00d7";
  }
}
