.mundo-nuevo-info{
	margin-top: 4%;

	.books{
	    list-style: none;
	    padding: 0;
	    display: table;
	    margin: 0 auto;

		li{
			display: table-cell;
			img{
				width: 80%;
				max-width: 198px;
			}
		}
	}

	.text{
		padding: 20px;
		background-color: rgba(255, 255, 255, 0.78);
		border-radius: 10px;
		margin-top: 1%;
		font-family: "MyriadPro", sans-serif;
		font-size: 17px;
		text-align: justify;
		color: #542763;

		@media screen and (max-width: $screen-xs) {
			font-size: 14px;
		}
	}

}
