/* Fonts */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-regular-webfont.eot');
  src: url('../fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-regular-webfont.woff') format('woff'),
       url('../fonts/montserrat-regular-webfont.ttf') format('truetype'),
       url('../fonts/montserrat-regular-webfont.svg#montserratregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('../fonts/MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadProCond';
  src: url('../fonts/MyriadPro-Cond.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadProCondBold';
  src: url('../fonts/MyriadPro-BoldCond.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DK';
  src: url('../fonts/DK Hobgoblin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MMText';
  src: url('../fonts/MMTextBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Media
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'Montserrat';
      src: url('../fonts/montserrat-regular-webfont.svg') format('svg');
    }
    select {
      font-family: Arial, Helvetica, sans-serif;
    }
}
