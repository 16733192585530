/**
 * Login Styles
 */

body, html{
    margin: 0;
}

.main-container{
    background-image: url('/assets/img/bkg-min.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}


.nav-mn-container{
    position: relative
}

.logosp{
    position: absolute;
    top: 15px;
    left: 15px;
    width: 150px;
}

.logo2{
    width: 80%;
    max-width: 582px;
    margin: 19% auto 0 auto;
    display: block;
}

.slide{
    margin-bottom: 50px;
}

.bkg-mn{
    background-image: url(/assets/img/bkg-min.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.fullpage-container{
    width: 100%;
    height: 100%;
    background-image: url('/assets/img/left_wt.png'), url('/assets/img/right_wt.png'), url('/assets/img/pattern.jpg');
    background-position: left bottom, right bottom, left top;
    background-size: 50% auto, 50% auto, auto 100%;
    background-repeat: no-repeat, no-repeat, repeat;

    @media screen and (max-width: $screen-lg) {
        background-size: 60% auto, 60% auto, auto 100%;
    }

    @media screen and (max-width: $screen-md) {
        background-size: 60% auto, 60% auto, auto 100%;
    }

    @media screen and (max-width: $screen-sm) {
        background-image: url('/assets/img/left_wt.png'), url('/assets/img/pattern.jpg');
        background-position: left bottom, left top;
        background-size: auto, auto 100%;
        background-repeat: no-repeat, repeat;
    }

    &:before{
        width: 100%;
        height: 50%;
        content: " ";
        position: absolute;
        background-image: url('/assets/img/texture1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


.btn.btn-mn{
    padding: 26px;
	font-size: 2em;
	margin: 5px 10px;
	border-radius: 15px;
	border: 2px solid white;
    font-family: "MyriadProCond", sans-serif;

    &.btn-mn-sm{
        margin: 3px 1px;
        padding: 7px 21px;
        border-radius: 10px;
        font-size: 19px;
        letter-spacing: 1px;
    }

    &:hover{
        border: 2px solid white !important;
    }

    span.glyphicon{
        vertical-align: text-bottom;
    }

}

.center-content{
    text-align: center;
}
