@mixin stitches-sprites($x: 0, $y: 0, $width: 0, $height: 0) {
    background-position: $x $y;
    width: $width;
    height: $height;
}

.sprites {
    background-image: url(/assets/img/spritesheet.png);    background-repeat: no-repeat;
    display: block;

    &.sprites-contact {
        @include stitches-sprites(-3px, -3px, 84px, 84px);
    }

    &.sprites-logo {
        @include stitches-sprites(-3px, -93px, 366px, 189px);
    }

    &.sprites-logosp {
        @include stitches-sprites(-375px, -3px, 183px, 184px);
    }

    &.sprites-logout {
        @include stitches-sprites(-93px, -3px, 84px, 84px);
    }

    &.sprites-profile {
        @include stitches-sprites(-183px, -3px, 84px, 84px);
    }

    &.sprites-profile_btn {
        @include stitches-sprites(-273px, -3px, 36px, 36px);
    }
}
