.register{

    margin: 50px auto;

    legend{
        font-family: "MyriadPro", sans-serif;
        padding-left: 10px;
    }

    .toggler{
        display: table;
        margin: 30px auto;
    }

    form{
        display: none;
    }

    label.control-label{
        font-family: "MyriadPro", sans-serif;
        margin-top: -7px;
        padding-top: 0px;
    }

    .help-block{
        font-family: "MyriadPro", sans-serif;
    }

    .panel-heading{
        font-family: "MMText", sans-serif;
    }
}
